import React from 'react'
import PageTitle from '../components/PageTitle'
import BackgroundVideo from '../assets/videos/project+vid.mp4'
import ProjectCard from '../components/ProjectCard'
import ElsrtMockup from '../assets/images/elsrt+mockup.webp'
import ElsrtLogo from '../assets/svgs/logo/elsrt+logo.svg'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo_white.svg'
import Tag from '../components/Tag'
import ProjectStacks from '../assets/images/projects/project+stacks.webp'
import SmileTrainMockup from '../assets/images/projects/smile+train+mockup.png'
import SmileTrainLogo from '../assets/images/projects/smiletrain-logo.svg'
import BridgeLogo from '../assets/images/projects/bridge+logo.svg'
import BridgeMockup from '../assets/images/projects/bridge+mockup.png'
import EkitiMockup from '../assets/images/projects/ekiti+health+mockup.png'
import EkitiLogo from '../assets/images/projects/ekiti+logo.png'
import KncvMockup from '../assets/images/projects/kncv+mockup.png'
import KncvLogo from '../assets/images/projects/kncv+logo2.svg'

const Project = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
  return (
    <>
        {/* Set Page title */}
        <PageTitle title="Projects | SeamHealth Website " /> 
        <div id='address-section' className="project-section pt-52 flex justify-between flex-col overflow-hidden">
          <div className="md:w-2/4 gap-8 items-center p-8">
            <video id='project-video' autoPlay loop muted >
                <source src={BackgroundVideo} type="video/mp4" />
            </video>
              <div className='text-white flex flex-col gap-6'>
                <div data-aos="fade-up">
                  <Tag title={"Portfolio"} alt={true} />
                </div>
                <h4 data-aos="fade-up" className="heading-four">Our Projects</h4>
              </div>
              <p data-aos="fade-up" className='text-white mt-3'>
              Discover how we’ve transformed healthcare with our innovative solutions. Our portfolio showcases a range of successful projects that highlight our expertise in delivering cutting-edge technology and improving patient care.
              </p>
          </div>

          <div className="grid grid-cols-1 gap-8 items-end">
              <img src={ProjectStacks} className='w-full' alt="" />
          </div>
        </div>

        {/* Services list */}
        <section className='py-52 service-list grid grid-cols-1 gap-x-6 gap-y-6 p-8'>
            <h4 className="heading-four text-center">Projects</h4>
            <ProjectCard 
              image={ClientshotMockup} 
              logo={ClientshotLogo} 
              bgColor={'purple'}
              tag={false}
              desc={"Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. "}
              link={'/projects/01/1'}
              landscape={true}
              />

            <ProjectCard 
              image={ElsrtMockup} 
              logo={ElsrtLogo} 
              tag={false}
              invert
              landscape={true}
              desc={"Electronic laboratory sample referral & tracking system (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."}
              link={'/projects/01/2'}
              />

              <ProjectCard 
              image={SmileTrainMockup} 
              logo={SmileTrainLogo} 
              tag={false}
              landscape={true}
              desc={"Smile Train empowers local medical professionals in 70+ countries to provide free cleft surgery and care through training and resources."}
              link={'/projects/01/3'}
              />

            <ProjectCard 
              image={BridgeMockup} 
              logo={BridgeLogo} 
              tag={false}
              invert
              landscape={true}
              desc={"The BRIDGE app is an Android m-Health tool for community health workers in rural Nigeria to improve early breast cancer detection."}
              link={'/projects/02/1'}
              />

            <ProjectCard 
              image={EkitiMockup} 
              logo={EkitiLogo} 
              tag={false}
              landscape={true}
              desc={"The ekiti health is a two-version web application that  consists of PVT (post vaccination tracking tool) and LIMS (Laboratory information management system)"}
              link={'/projects/02/2'}
              />

            <ProjectCard 
              image={KncvMockup} 
              logo={KncvLogo} 
              tag={false}
              invert
              landscape={true}
              desc={"Keep track of patients' USSD records, monitor the follow up of patients with tuberculosis and visualize key statistical insights."}
              link={'/projects/02/3'}
              />
        </section>
    </>
  )
}

export default Project