import React from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import Logo from '../assets/svgs/logo/seamhealth+logo.svg'
import LogoAlt from '../assets/svgs/logo/seamhealth+logo+alt.svg'
import InstagramIcon from '../assets/svgs/icons/instagram+icon.svg'
import FacebookIcon from '../assets/svgs/icons/facebook+icon.svg'
import TwitterIcon from '../assets/svgs/icons/twitter+icon.svg'
import LinkedinIcon from '../assets/svgs/icons/linkedin+icon.svg'
import YoutubeIcon from '../assets/svgs/icons/youtube+icon.svg'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'

const Footer = () => {

  React.useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: 'ease-out-cubic',
      delay: 100,
    });
    
    // AOS.refresh();
  }, []);
  return (
    <>
      <div className="jumbotron p-4 py-12 md:p-28 flex flex-col md:flex-row justify-between gap-8 md:gap-0 md:items-center">
          <div className='flex flex-col gap-6'>
              <h4 data-aos="fade-up" className="heading-three md:w-3/5">
                Transform Your Business with Our Software Solutions
              </h4>
              <p data-aos="fade-up" className='md:w-4/5'>
                Experience a new level of efficiency and growth with our custom software solutions. Stay ahead of the competition and harness technology to your advantage
              </p>
          </div>
          
          <div data-aos="fade">
            <Link to={"/get-in-touch"} className='btn btn-primary w-max'>Contact us <img src={ArrowUpRight} alt="" /></Link>
          </div>
      </div>

      <div className='p-22'>

      <footer className='mt-16 p-8 grid grid-cols-1 md:grid-cols-2 gap-2  md:flex-row'>
            <div className='md:w-2/4'>
                <Link to={'/'}><img src={LogoAlt} alt="" /></Link>
                <div className="about-desc mt-3">
                  SeamHealth is a leader in healthcare innovation, dedicated to providing comprehensive consulting and software development services                
                </div>
                <Link to={"/about"} aria-label="Explore Our Story" className='btn-link' >Continue Reading</Link>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 mt-8 md:mt-0 gap-12'>
              <div className='w-full'>
                  <h6>Company</h6>
                  <ul className='mt-6'>
                    <Link to="/about">
                      <li className='nav-link'>About us</li>
                    </Link>
                    <a href={"/get-in-touch"}>
                      <li className='nav-link mt-4'>Contact</li>
                    </a>
                    <Link to={"/services"}>
                      <li className='nav-link mt-4'>Services</li>
                    </Link>
                    <Link to={"/projects"}>
                      <li className='nav-link mt-4'>Projects</li>
                    </Link>
                  </ul>
              </div>

              <div className=''>
                  <h6>Projects</h6>
                  <ul className='mt-6'>
                  <Link to={"/projects/01/1"}>
                    <li className='nav-link mt-4'>Clientshot</li>
                  </Link>
                  <Link to={"/projects/01/2"}>
                    <li className='nav-link mt-4'>ELSRT</li>
                  </Link>
                  <Link to={"/projects/01/3"}>
                    <li className='nav-link mt-4'>SmileTrain</li>
                  </Link>
                  <Link to={"/projects/02/1"}>
                    <li className='nav-link mt-4'>Bridge</li>
                  </Link>
                  <Link to={"/projects/02/2"}>
                    <li className='nav-link mt-4'>Ekiti Health</li>
                  </Link>
                  <Link to={"/projects/02/3"}>
                    <li className='nav-link mt-4'>KNCV</li>
                  </Link>
                  </ul>
              </div>


              <div className=''>
                  <h6>Services</h6>
                  <ul className='mt-6'>
                    <Link to="/services/1">
                      <li className='nav-link'>Consultation</li>
                    </Link>
                    <Link to="/services/2">
                      <li className='nav-link mt-4'>Custom Software Development</li>
                    </Link>
                    <Link to="/services/3">
                      <li className='nav-link mt-4'>Mobile App Development</li>
                    </Link>
                    <Link to="/services/4">
                      <li className='nav-link mt-4'>System Integration</li>
                    </Link>
                    <Link to="/services/5">
                      <li className='nav-link mt-4'>Legacy System Modernization</li>
                    </Link>
                    <Link to="/services/6">
                      <li className='nav-link mt-4'>Support and Maintenance</li>
                    </Link>
                    
                  </ul>
              </div>

             
            </div>
        </footer>

        <div className="flex flex-col md:flex-row md:justify-between items-center gap-y-8 p-8 mt-16">
          <div className="socials flex gap-4">
              <a href="https://www.instagram.com/seamhealth/profilecard/?igsh=MTZ1bmRzN3VxdHVnOA%3D%3D" target='_blank' rel='noreferrer'><img src={InstagramIcon} alt="" /></a>
              <a href="https://www.linkedin.com/company/seamhealth/" target='_blank' rel='noreferrer'><img src={LinkedinIcon} alt="" /></a>
              <a href="https://www.facebook.com/seamhealth?mibextid=ZbWKwL" target='_blank' rel='noreferrer'><img src={FacebookIcon} alt="" /></a>
          </div>

          <div className="copyright flex flex-row items-center gap-8">
          <p className='text-center' style={{color : '#8F8F8F'}}>© 2024, Seamhealth Innovations. All Rights Reserved </p>
          
          </div>

          {/* <Link to={'/sitemap.xml'}>
              <p className='nav-link'>Site map</p>
            </Link> */}
        </div>
      </div>
    </>
  )
}

export default Footer