// import {React, useEffect} from 'react'
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, FreeMode } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import CircleIcon from '../assets/svgs/icons/circle+icon.svg'
import BackgroundVideo from '../assets/videos/futuristic+background.mp4'
import AboutVid from '../assets/videos/about+vid.mp4'
import Thumbnail from '../components/Thumbnail'
import ConsultationImg from '../assets/images/Consultation.webp'
import WebDevImg from '../assets/images/development.webp'
import AppDevImg from '../assets/images/app+development.webp'
import LightBulbIcon from '../assets/svgs/icons/light+bulb+icon.svg'
import TelehealthIcon from '../assets/svgs/icons/telehealth+icon.svg'
import Chip from '../components/Chip'
import ClientshotMockup from '../assets/images/clientshot+mockup.png'
import ElsrtMockup from '../assets/images/elsrt+mockup.webp'
import ElsrtLogo from '../assets/svgs/logo/elsrt+logo.svg'
import ClientshotLogo from '../assets/svgs/logo/Clientshot_logo_white.svg'
import ProjectCard from '../components/ProjectCard'
import Ihvn from '../assets/images/clients/ihvn.svg'
import Smiletrain from '../assets/images/clients/smiletrain.svg'
import Asco from '../assets/images/clients/asco.svg'
import Phr from '../assets/images/clients/phr.svg'
import Kncv from '../assets/images/clients/kncv.svg'
import Smile from '../assets/images/clients/smile.svg'
import Cdc from '../assets/images/clients/cdc.svg'
import Scidar from '../assets/images/clients/scidar.svg'
import ApproachCard from '../components/ApproachCard'
import SolutionDesignIcon from '../assets/svgs/icons/solution+design.svg'
import AssessmentIcon from '../assets/svgs/icons/approach/assessment+icon.svg'
import TechnologyIcon from '../assets/svgs/icons/approach/technology+icon.svg'
import TestingIcon from '../assets/svgs/icons/approach/testing+icon.svg'
import MaintenanceIcon from '../assets/svgs/icons/approach/maintenance+icon.svg'
import PerformanceIcon from '../assets/svgs/icons/approach/performance+icon.svg'
import DataIcon from '../assets/svgs/icons/approach/data+icon.svg'
import ConfigurationIcon from '../assets/svgs/icons/approach/configuration+icon.svg'
import ArrowUpRight from '../assets/svgs/icons/arrow-up-right.svg'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import MobileHealthIcon from '../assets/svgs/icons/solutions/mobile+health+icon.svg'
import EHRIcon from '../assets/svgs/icons/solutions/ehr+icon.svg'
import PatientPortalIcon from '../assets/svgs/icons/solutions/patient+portal+icon.svg'
import HealthAnalyticsIcon from '../assets/svgs/icons/solutions/healthcare+analytics.svg'

import AppointmentIcon from '../assets/svgs/icons/solutions/appointment+icon.svg'
import PatientMonitoringIcon from '../assets/svgs/icons/solutions/patient+monitoring+icon.svg'
import MedicalBillIcon from '../assets/svgs/icons/solutions/medical+billing+icon.svg'
import ClinicalDecisionIcon from '../assets/svgs/icons/solutions/clinic+decision+icon.svg'
import PracticeIcon from '../assets/svgs/icons/solutions/practice+icon.svg'


const Home = () => {
  React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page

  React.useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: 'ease-out-cubic',
      delay: 100,
    });
    
    AOS.refresh();
  }, []);

  return (
    <>
      {/* Set Page title */}
      <PageTitle title="Home | SeamHealth Website " /> 
        <div className="hero-section h-full flex flex-col items-center justify-center">
            <video id="background-video" autoPlay loop muted >
                <source src={BackgroundVideo} type="video/mp4" />
              </video>
            <div className='hero-texts flex flex-col gap-6 items-center p-8 md:p-0'>
                <h3 data-aos="fade-up"  className='heading-three'>Empowering Healthcare Excellence Through Seamless Solutions</h3>
                <p data-aos="fade-up" className='body'>We offer innovative technology solutions aimed at improving patient care, boosting operational efficiency, and driving impactful results.</p>
                <div data-aos="fade-up">
                  <Link to={"/about"} className='btn btn-primary w-max'>Read more <img src={ArrowUpRight} alt="" /></Link>
                </div>
            </div>

            <a href="#about-section">
                <div className='scroll-cta flex gap-3 items-center'>
                    <img src={CircleIcon} alt="" />
                    <p className='text'>Scroll to explore</p>
                </div>
            </a>
        </div>

              {/* About section */}
        <div className='about-section grid grid-cols-1 md:grid-cols-2 justify-center' id='about-section'>
              <div className='p-4 md:p-8 pt-24 md:py-52'>
                <h4 data-aos="fade-up" className='heading-three mb-4'>About Seamhealth</h4>
                <Link data-aos="fade-up" to="/about" className='btn btn-primary w-max'>Read more about us <img src={ArrowUpRight} alt="" /></Link>  
              </div>
              
              <div className='p-4 md:p-8 md:py-52'>
                  <p data-aos="fade-up" className='mb-4 text-gray ' style={{color : '#474747'}}>
                    SeamHealth is a leader in healthcare innovation, dedicated to providing comprehensive consulting and software development services. 
                  </p>
                  <p data-aos="fade-up" className='mb-4 text-gray' style={{color : '#474747'}}>
                    Our mission is to empower healthcare organizations with seamless, efficient, and effective solutions that enhance patient outcomes and operational excellence. 
                  </p>
                  <p data-aos="fade-up" className='mb-4 text-gray ' style={{color : '#474747'}}>
                    With a team of seasoned professionals, we combine industry expertise with innovative thinking to deliver exceptional results.
                  </p>
                  <div>
                    <video autoPlay loop muted >
                        <source src={AboutVid} type="video/mp4" />
                    </video>
                  </div>
              </div>
        </div>

        {/* Services section */}
        <div className='services-section pt-16 md:pt-52'>
              <div className='flex flex-col md:flex-row md:justify-between items-start md:items-center p-4 md:p-8'>
                  
                  <div className="flex gap-y-4 flex-col">
                    <h4 data-aos="fade-up" className="heading-three">Our Services</h4>
                    <p data-aos="fade-up" className='text-gray md:w-[80%]'>
                      Discover our expertise in consultation, custom software development and mobile app development. We deliver tailored solutions to meet your unique business needs.
                    </p>
                  </div>

                  <button data-aos="fade" className='btn btn-primary mt-8 md:mt-0'>View all services <img src={ArrowUpRight} alt="" /></button>
              </div>

              {/* block services with images */}
              <div className="grid grid-cols-1 md:grid-cols-3">
                <Thumbnail link={"/services/1"} image={ConsultationImg} title={"Consultation"}  desc={"Offering expert advice and strategies to optimize your healthcare operations and technology implementations."} />
                <Thumbnail link={"/services/2"} image={WebDevImg} title={"Custom Software Development"} desc={"Creating tailored healthcare solutions from scratch, designed to meet the unique needs of your institution or practice."} />
                <Thumbnail link={"/services/3"} image={AppDevImg} title={"Mobile App Development"} desc={"Building user-friendly mobile applications for both patients and healthcare providers, enabling access to health services anytime, anywhere."} />
              </div>
        </div>

        

        {/* solution */}
        <div className='solution-section py-48 p-4 md:p-8 '>
              <div className="s-inner flex flex-col justify-center items-center rounded-2xl gap-2">
                {/* <div> */}
                  
                  <img src={LightBulbIcon} alt="" className='mb-8' />
                    <h4 data-aos="fade-up" className="heading-three mb-4">
                      Solutions We Build
                    </h4>
                    <p data-aos="fade-up" className="text-gray text-center md:w-4/5">
                    At SeamHealth, we offer a variety of tailored solutions designed to enhance the efficiency, quality, and accessibility of healthcare services. Explore our range of solutions below to see how we can help your organization thrive.
                    </p>
                {/* </div> */}
                <div className="crescent"></div>
              </div>

              <div className="hidden md:block my-8">
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay, FreeMode]}
                  spaceBetween={20}
                  slidesPerView={4}
                  freeMode={true} 
                  autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                      
                    }}
                    longSwipes={true}
                    // shortSwipes={false}
                    speed={1500}
                  // navigation
                  loop={true}
                  
                  >
                    <SwiperSlide><Chip title={"Electronic Health Records (EHR) Systems"} icon={EHRIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Mobile Health Applications"} icon={MobileHealthIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Telehealth Platforms"} icon={TelehealthIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Patient Portals"} icon={PatientPortalIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Healthcare Analytics"} icon={HealthAnalyticsIcon} /></SwiperSlide>
                  </Swiper>
              </div>

                <div className="hidden md:block">
                  <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay, FreeMode]}
                  spaceBetween={20}
                  slidesPerView={4}
                  autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                      reverseDirection: true,
                      loop : true
                    }}
                  longSwipes={true}
                  
                  speed={1500}
                  loop={true}
                  >
                    <SwiperSlide><Chip title={"Appointment Scheduling Systems"} icon={AppointmentIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Remote Patient Monitoring"} icon={PatientMonitoringIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Medical Billing Solutions"} icon={MedicalBillIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Clinical Decision Support Systems"} icon={ClinicalDecisionIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Practice Management Software"} icon={PracticeIcon} /></SwiperSlide>
                  </Swiper>
                </div>

                <div className="for-mobile md:hidden my-8">
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={1}
                  autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                    }}
                    longSwipes={true}
                    // shortSwipes={false}
                    speed={1500}
                  // navigation
                  loop={true}
                  
                  >
                    <SwiperSlide><Chip title={"Electronic Health Records (EHR) Systems"} icon={EHRIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Mobile Health Applications"} icon={MobileHealthIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Telehealth Platforms"} icon={TelehealthIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Patient Portals"} icon={PatientPortalIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Healthcare Analytics"} icon={HealthAnalyticsIcon} /></SwiperSlide>
                  </Swiper>
              </div>

                <div className='for-mobile md:hidden '>
                  <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={1}
                  autoplay={{
                      delay: 500,
                      disableOnInteraction: false,
                      reverseDirection: true
                    }}
                  longSwipes={true}
                  speed={2000}
                  loop={true}
                  >
                    <SwiperSlide><Chip title={"Appointment Scheduling Systems"} icon={AppointmentIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Remote Patient Monitoring"} icon={PatientMonitoringIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Medical Billing Solutions"} icon={MedicalBillIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Clinical Decision Support Systems"} icon={ClinicalDecisionIcon} /></SwiperSlide>
                    <SwiperSlide><Chip title={"Practice Management Software"} icon={PracticeIcon} /></SwiperSlide>
                  </Swiper>
                </div>
        </div>

        {/* recent project */}

        <div className="recent-projects p-4 md:p-8 ">
          <div className='flex flex-col md:flex-row justify-between items-start gap-4 md:gap-0 md:items-center'>
          
              <div className="flex gap-y-2 flex-col">
                <h4 data-aos="fade-up" className="heading-four">Recent Projects</h4>
                <p data-aos="fade-up" className='text-gray'>
                See how we are advancing healthcare with our recent projects
                </p>
              </div>
              <Link to="/projects" data-aos="fade" className='btn btn-primary'>See all projects <img src={ArrowUpRight} alt="" /></Link>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 my-24 gap-8'>
            <ProjectCard 
              image={ClientshotMockup} 
              logo={ClientshotLogo} 
              bgColor={'purple'}
              desc={"Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers. "}
              link={'/projects/01/1'}
              />

              <ProjectCard 
              image={ElsrtMockup} 
              logo={ElsrtLogo} 
              desc={"Electronic laboratory sample referral & tracking system (ELSRT) is a software-based solution with features that support a modern laboratory’s operations."}
              link={'/projects/01/2'}
              />
          </div>
        </div>

        {/* Approach */}
        <div>
          <div className="approach-section py-28 p-4 md:p-8 flex flex-col md:flex-row gap-8 items-start">
            <div className='text-white md:sticky top-36 approach-text py-24'>
                <h4 className="heading-three">Our Approach</h4>
                <p className='mt-4'>
                We take a thorough and detailed approach to healthcare IT consulting. As a leading healthcare technology consulting company, we begin by carefully evaluating your current IT setup, identifying areas for improvement, and ensuring a smooth and successful app launch.
                </p>
          
            </div>
            <div className='approach flex flex-col gap-4'>
          
                <ApproachCard
                  icon={AssessmentIcon}
                  position={1}
                  title="Need Assessment"
                  desc="We kick off our healthcare IT consulting by carefully reviewing the client’s IT processes and infrastructure to find trouble spots and areas that need improvement."
                  />
          
                <ApproachCard
                  icon={SolutionDesignIcon}
                  position={2}
                  title="Solution Design"
                  desc="Our experts in healthcare software consulting create solutions based on your specific requirements. They produce architecture designs, workflow diagrams, and data models to illustrate how the solutions will work with your existing systems."
                  />
                <ApproachCard
                  icon={TechnologyIcon}
                  position={3}
                  title="Technology Selection"
                  desc="Our experts in healthcare software consulting create solutions based on your specific requirements. They produce architecture designs, workflow diagrams, and data models to illustrate how the solutions will work with your existing systems."
                  />
          
                  <ApproachCard
                  icon={ConfigurationIcon}
                  position={4}
                  title="System Configuration and Development"
                  desc="At this point, our experts set up the selected technology, software, and hardware to meet design specifications perfectly. As a leading healthcare IT consulting company, we ensure an efficient system by adjusting settings, making connections, and ensuring smooth interoperability."
                  />
                <ApproachCard
                  icon={DataIcon}
                  position={5}
                  title="Data Migration"
                  desc="We kick off our healthcare IT consulting by carefully reviewing the client’s IT processes and infrastructure to find trouble spots and areas that need improvement."
                  />
                <ApproachCard
                  icon={TestingIcon}
                  position={6}
                  title="Testing"
                  desc="After achieving successful data migration, our experts diligently test and address any technical issues, defects, or performance issues in the solutions."
                  />
                <ApproachCard
                  icon={PerformanceIcon}
                  position={7}
                  title="Performance Monitoring"
                  desc="Tracking performance is key in our healthcare software development process. Our consulting experts in healthcare software help you monitor issues by regularly assessing the effectiveness of new solutions."
                  />
                <ApproachCard
                  icon={MaintenanceIcon}
                  position={8}
                  title="Updates and Maintenance"
                  desc="Our responsibility goes beyond this point. Through our full healthcare IT consulting services, we continuously update and maintain your systems, adding new features, securing fixes, and making enhancements."
                  />
          
            </div>
          </div>
        </div>
        

        {/* clients */}
        <div className='client-section grid grid-cols-1 md:grid-cols-2 py-8 md:py-28 p-8 gap-8 items-center h-max md:h-[80vh]'>
            <div className='clients grid grid-cols-2 md:grid-cols-4 gap-4'>
                <div className="client">
                  <img src={Ihvn} alt="" />
                </div>
                <div className="client">
                  <img src={Smiletrain} alt="" />
                </div>
                <div className="client">
                  <img src={Asco} alt="" />
                </div>
                <div className="client">
                  <img src={Phr} alt="" />
                </div>
                <div className="client">
                  <img src={Kncv} alt="" />
                </div>
                <div className="client">
                  <img src={Smile} alt="" />
                </div>
                <div className="client">
                  <img src={Cdc} alt="" />
                </div>
                <div className="client">
                  <img src={Scidar} alt="" />
                </div>

            </div>

            <div className='flex justify-center items-center h-max order-first md:order-last'>
                <h4 className="heading-three">Client we've worked with</h4>
            </div>
        </div>
    </>
  )
}

export default Home