import React from 'react'

const TeammateCard = ({ image, name, role }) => {

  return (
    <>
        <div className='teammate flex flex-col gap-4'>
            <img className='portrait' src={image} alt="" draggable="false" />
            <div>
                <h6 className="heading-five text-primary500 font-semibold">{name}</h6>
                <p className='text-gray'>{role}</p>
            </div>
        </div>
    </>
  )
}

export default TeammateCard